@media screen and (min-width:1600px) {
  .reset_left_div {
    height: 100vh;
    padding: 12% 0;
  }

  .text-gray-signup {
    font: normal normal 500 22px/25px Raleway !important;
  }

  .ant-input {
    height: 50px;
  }

  .login-form-button {
    height: 65px !important;
    border-radius: 1rem !important;
  }

  .partner_stat_picker {
    height: 75px;
    font-size: 16px !important;
  }

  .ant-btn>span {
    font-size: 1rem;
  }

  .inputText:focus~.floating-label,
  .inputText:not(:focus):valid~.floating-label {
    bottom: 20px;
  }

  h3 {
    font: normal normal bold 28px/45px Raleway !important;
  }

  h4 {
    font: 500 28px/45px Raleway !important;
  }

  .footer_text a {
    font: normal normal bold 22px Raleway;
  }

  .ver_btn_width {
    width: 35%;
  }

  .vi__character {
    width: 100px;
    height: 100px !important;
    line-height: 100px !important;
    font-size: 45px;
  }

  .content_padding {
    padding: 40px 20px 0 20px;
  }

  .receivecode {
    font-size: 20px;
  }

  .resendcode {
    font-size: 20px;
  }

  .floating-label {
    font-size: 22px;
    bottom: 42px;
  }

  .inputText:focus~.floating-label,
  .inputText:not(:focus):valid~.floating-label {
    bottom: 42px;
  }
}

@media screen and (max-width:1143px) {
  .ver_btn_width {
    width: 70%;
  }
}

@media screen and (max-width:992px) {
  .ver_btn_width {
    width: 40%;
  }
}

@media screen and (max-width:767px) {
  .background-theme>img {
    width: 50%;
  }

  .reset_left_div {
    height: 100%;
  }

  .ver_btn_width {
    width: 50%;
  }
}

@media screen and (max-width:670px) {
  .login-form-button {
    width: 100%;
    height: 50px !important;
  }

  .partner_stat_picker {
    height: 50px !important;
    font-size: 16px !important;
  }

  .login-form {
    padding: 0 3rem !important;
  }

  .ver_btn_width {
    width: 40%;
  }

  .vi__character {
    width: 50px;
    height: 50px !important;
    line-height: 60px !important;
    font-size: 25px;
  }

  .content_padding {
    padding: 0px 20px 0 20px;
  }

  .text-gray-signup {
    font: normal normal 500 10px/15px Raleway !important;
  }


  .ant-input {
    height: 40px;
  }

  .submit_btn {
    height: 40px !important;
  }

  .forgot_left_div {
    height: 100%;
  }

  .reset_left_div {
    height: 100%;
  }

  .floating-label {
    font-size: 10px;
    bottom: 20px;
  }

  .inputText:focus~.floating-label,
  .inputText:not(:focus):valid~.floating-label {
    bottom: 20px;
  }

  .ant-input {
    font-size: 10px !important;
  }
}

@media screen and (max-width:450px) {
  .verification .ant-input {
    height: 50px;
    width: 50px;
  }

  .verification ::placeholder {
    font-size: 1.5rem !important;
  }

  .ant-form-item-explain-error {
    font-size: 10px !important;
  }

  .dont_acc {
    bottom: 10px;
    left: 30%;
  }
}

@media screen and (max-width:425px) {
  .logo_img {
    width: 50% !important;
  }

  .ver_btn_width {
    width: 60%;
  }

  .login-form {
    padding: 0 1.5rem !important;
  }

  h3 {
    font: normal normal bold 13px/16px Raleway !important;
  }

  h4 {
    font: 500 13px/16px Raleway !important;
  }

  .text-dark {
    font: normal normal 800 12px/20px Raleway;
  }

  .text-gray {
    font: normal normal 600 10px/20px Raleway;
  }

  .ant-card-body {
    padding: 1rem !important;
  }

  .choose_us_text {
    font: normal normal 800 18px/20px Raleway;
  }

  .text-white {
    font-size: 10px;
    line-height: 15px;
  }

  .download_text_para2 {
    font: normal normal normal 12px/25px Raleway;
  }
}





/* @media only screen and (max-width: 600px) {
    .suspense_logo{
        width: 40%;
    }
    .suspense_text{
    font:600 1.2rem Raleway;
    }
    .section {
        padding: 2rem 0.5rem;
    }
    .logo {
        width: 100%;
      }

    .head-card {
        position: relative;
        width: 100%;
    }

    .mnone {
        display: none!important;
    }

    .text-team {
        transform: none;
        transform-origin: 0rem 0rem !important;
    }

    .mobiles_ img {
        margin-top: 0;
        position: relative;
        right: 0;
    }
    .footer-menu ul {
        display: block;
        line-height: 2.5rem;
    }

    .login-form {
        padding-left: 0rem !important;
        padding-right: 0rem !important;
    }

    .login-form-button{
        width: 100%;
    }

    .verification .ant-input {
        height: 65px!important;
        width: 55px!important;
      }



    .xs-plr-1{
        padding-left: 1rem!important;
        padding-right: 1rem!important;
    }
    .xs-mt-0{
       margin-top: 0rem!important;
    }

} */